import { getFirebaseSingleton } from "@src/lib/firebase";
import { LocalStorage } from "@store/session/session.model";

export const getAuthHeader = async () => {
  const token = await getAuthToken();

  return { Authorization: token };
};

// Fetches local token if not expired or makes API call to return refreshed token
// onIdTokenChanged() event triggers for new token that sets token in local storage
export async function getAuthToken() {
  return (
    (await getFirebaseSingleton().currentUser?.getIdToken()) ||
    localStorage.getItem(LocalStorage.AUTH_TOKEN)
  );
}
